/** @jsx jsx */

import { Styled, jsx, Flex, Box, Grid } from "theme-ui"

import { graphql } from 'gatsby'

import styled from '@emotion/styled'

import Card from '../components/Card'

import Layout from "../components/layout"

import SEO from "../components/seo"

import BasicHero from '../components/BasicHero'
import BgImage from '../components/BgImage'

import ContactSection from '../components/ContactSection'


import Button from "../components/button"


/* Application Case Icon Group*/
import ComfortIcon from '../images/icons/comfort-cooling-application-icon.svg'
import EnergyIcon from '../images/icons/energy-savings-application-icon.svg'
import GlassIcon from '../images/icons/glass-protection-application-icon.svg'
import PrivacyIcon from '../images/icons/privacy-security-application-icon.svg'
import UvIcon from '../images/icons/uv-protection-application-icon.svg'
import ValueIcon from '../images/icons/value-style-application-icon.svg'

/* Unique Application Icons */
import HygeneIcon from '../images/icons/hygene-icon.svg'
import SecurityIcon from '../images/icons/security-icon.svg'
import TechnologyIcon from '../images/icons/technology-use-icon.svg'


import { Container } from '../components/item'





export const query = graphql`
  query {
    hero: file(relativePath: { eq: "Raptect-applications-Parent-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interior: file(relativePath: { eq: "advantage-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auto: file(relativePath: { eq: "auto-use-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marine: file(relativePath: { eq: "marine-use-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    architecture: file(relativePath: { eq: "architecture-use-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blueBackground: file(relativePath: { eq: "blue-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    purpleBackground: file(relativePath: { eq: "purple-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const StyledBox = styled(Box)`
  h1 {
    font-family: "Open Sans",sans-serif;
    font-weight: 800;
    font-size: 32px;
    text-transform: uppercase;
    margin-top: 8px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  p {
    font-size: 20px;
    line-height: 1.2;
    font-weight: normal;
  }
`

const Applications = ({data}) => (

  <Layout>
    <SEO title="Raptect Applications" />
    
    <BasicHero 
      fluid={data.hero.childImageSharp.fluid}  
      title={"Nondescript Building Image"} 
      heroTitle={"Raptect Applications"}
      shapeColor={'transparent'}
    />
    
    <Box as="section" bg={'white'} color={'black'}>
      <Container>
        <Grid columns={[1, 2, 3]} py={12} gap={8} sx={{justifyItems: 'center', alignItems: 'space-between'}}>
          <Card
            color='black'
            titleColor='black'
            Icon={UvIcon}
            small
            title="uv protection" 
            text="UV exposure can damage your property and increase your risk of skin cancer. Our tints block 99% of the sun's harmful rays">
            <Button variant={'blackOutline'} to="/applications/uv-protection" text="Explore More" />
          </Card>
          <Card
            color='black'
            titleColor='black'
            Icon={GlassIcon}
            small
            title="glass protection" 
            text="No matter the window type, our nano ceramic films hold shattered glass and keep breakage to a minimum" 
          >
            <Button variant={'blackOutline'} to="/applications/glass-protection" text="Explore More" />
          </Card>
          <Card
            color='black'
            titleColor='black'
            Icon={ComfortIcon}
            small
            title="comfort and cooling" 
            text="Transform the spaces where you spend your time. Cool down a car, home, or office for cost-effective comfort and reduced glare" 
          >
            <Button variant={'blackOutline'} to="/applications/comfort-and-cooling" text="Explore More" />
          </Card>
          <Card
            color='black'
            titleColor='black'
            Icon={EnergyIcon}
            small
            title="energy saving" 
            text="Nano ceramic tint brings down energy costs in both private homes and large, commercial buildings - no matter the season" 
          >
            <Button variant={'blackOutline'} to="/applications/energy-saving" text="Explore More" />
          </Card>
          <Card
            color='black'
            titleColor='black'
            Icon={PrivacyIcon}
            small
            title="privacy and security" 
            text="Protect privacy, discourage crime. Raptect tint reduces transparency and makes windows more difficult for intruders to break" 
          >
            <Button variant={'blackOutline'} to="/applications/privacy-and-security" text="Explore More" />
          </Card>
          <Card
            color='black'
            titleColor='black'
            Icon={ValueIcon}
            small
            title="value and style" 
            text="Customize your vehicle for a dramatic, impressive style. Increase the appeal of a car - or home - while protecting your assets" 
          >
            <Button variant={'blackOutline'} to="/applications/value-and-style" text="Explore More" />
          </Card>
        </Grid>
      </Container>
    </Box>

    <Box as='section' color='white' bg='gold'>
      <Container py={13}>
        <Grid columns={[1, 3]} gap={[8, 8, 12]}>           
            <StyledBox>
              <Styled.h2 sx={{maxWidth: '250px'}}>Professional Installation</Styled.h2>
              <Styled.p variant={'text.body'}>Installed on the inside of a window, Raptect window tint conforms to flat or curved surfaces. Professional installation is recommended.</Styled.p>
            </StyledBox>
            <StyledBox>
              <Styled.h2 sx={{maxWidth: '250px'}}>Smart Technology</Styled.h2>
              <Styled.p variant={'text.body'}>Nano ceramic technology offers protection and temperature control for every season.  Keeping heat in through the winter and out during the summer, it minimizes the need for heating and cooling.</Styled.p>
            </StyledBox>
            <StyledBox>
              <Styled.h2 sx={{maxWidth: '250px'}}>Lifetime Value</Styled.h2>
              <Styled.p variant={'text.body'}>Choosing a high-grade window tint extends the lifetime of interior furnishings, windows, and the window film itself. Save on replacements and upkeep with a shield that will go the distance</Styled.p>
            </StyledBox>
        </Grid>
      </Container>
    </Box>

    <BgImage
      fluid={data.blueBackground.childImageSharp.fluid}
      title="abstract background"
    >
      <Container py={13} sx={{textAlign: 'center', color: 'white', backgroundColor: ['blue', 'transparent']}}>
        <Styled.h2 sx={{marginY: '80px'}}>Unique Applications</Styled.h2>
        <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center','flex-start'] }}>
          <Card
            color='white'
            Icon={HygeneIcon}
            titleColor='white'
            title="Hygene"
            text="Hygienic options to increase patient privacy and energy efficiency in healthcare centers."
          >
          </Card>
          <Card
            color='white'
            Icon={SecurityIcon}
            titleColor='white'
            title="Security"
            text="Privacy is security. Nano ceramic tint can increase glass opacity and strength in secure facilities"
          >
          </Card>
          <Card
            color='white'
            Icon={TechnologyIcon}
            titleColor='white'
            title="Technology"
            text="Protect sensitive electrical and tech systems from damage caused by sunlight and heat."
          >
          </Card>
        </Flex>
      </Container>
    </BgImage>

    <BgImage
      fluid={data.purpleBackground.childImageSharp.fluid}
      title="abstract background"
    >
      <Container py={13} sx={{
        textAlign: 'center',
        color: 'white'
      }}>
        <Styled.h2 as="span" sx={{
          textAlign: 'center',
          fontWeight: 'bold'
        }}>Industries We Serve</Styled.h2>
        <Flex width='100%' sx={{
          py: '40px',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
          alignItems: ['center', 'flex-start'],
        }}>
        <Card
            color='white'
            titleColor='white'
            fluidImg={data.auto.childImageSharp.fluid}
            title="auto" 
            text="Vehicle windows are damage-prone. Improve their safety, lifespan, and sun protection with professional tinting." 
          />
          <Card
            color='white'
            titleColor='white'
            roundImage
            fluidImg={data.marine.childImageSharp.fluid}
            title="marine" 
            text="Shipbuilding industries and boat repair businesses alike need quality tints to reduce UV damage, breakage risks, heat, and glare." 
          />
          <Card
            color='white'
            titleColor='white'
            roundImage
            fluidImg={data.architecture.childImageSharp.fluid}
            title="architecture" 
            text="Enhance window performance with Raptect heating, cooling, and UV solutions for homes and commercial buildings." 
          />
          </Flex>
          <Button variant={'whiteOutline'} to={'/industry-resources'} text="Learn More" sx={{minWidth: '250px'}}/>
      </Container>
    </BgImage>

    <BgImage
      fluid={data.interior.childImageSharp.fluid}
      title="abstract background"
    >
      <Container pt={14} pb={13} sx={{textAlign: 'center', color: 'white', backgroundColor: ['blue', 'transparent']}}>
        <Styled.h2 sx={{marginY: '40px'}}>The Raptect Advantage</Styled.h2>
        <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center','flex-start'] }}>
          <Card
            color='white'
            title="Cool, comfortable protection"
            titleColor='white'
            text="Cooling comfort and protection from the sun’s harmful rays"
          >
          </Card>
          <Card
            color='white'
            title="Safe, secure, dependable"
            titleColor='white'
            text="You can depend on Raptect window tints to protect your safety and privacy"
          >
          </Card>
          <Card
            color='white'
            title="Accurate, refined, detailed"
            titleColor='white'
            text="Our tints apply accurately and imperceptibly for a sophisticated look."
          >
          </Card>
        </Flex>
      </Container>
    </BgImage>

    <ContactSection title="Contact Us">
        <Styled.h2>Value</Styled.h2>
        <Styled.p>We’re committed to providing exclusive products at exceptional price points.</Styled.p>
    </ContactSection>
  
  </Layout>
)

export default Applications
